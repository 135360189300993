/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          var oldScroll;

        // JavaScript to be fired on all pages
        $('.fa-navicon').on('click',function(e){
          e.preventDefault();
          $('.top-nav ul:not(.sub-menu)').slideToggle();
        });

          $(window).scroll(function() {
              var path = "wp-content/themes/vkph-theme/dist/images/";
              var wintop = $(window).scrollTop(), docheight = $('.content').height(), winheight = $(window).height();
              var totalScroll = (wintop/(docheight-winheight))*100;

              if(totalScroll < 95){
                  $("header .spacer").css("width",totalScroll+"%");

                  if(oldScroll > totalScroll){
                      $('.baby').hide();
                      $('.baby-left').show();
                  } else {
                      $('.baby-left').hide();
                      $('.baby').show();
                  }
              }
              oldScroll = totalScroll;
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // $('.image-slider').flexslider({
        //     animation: "slide"
        //   });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Javascript to be fired on the Contact page.
    'contact': {
      init: function() {
          /* Validation Events for changing response CSS classes */
          document.addEventListener( 'wpcf7invalid', function( event ) {
              $('.wpcf7-response-output').addClass('alert alert-danger').removeClass('wpcf7-validation-errors wpcf7-response-output').css('font-size', '14px');
          }, false );
          document.addEventListener( 'wpcf7spam', function( event ) {
              $('.wpcf7-response-output').addClass('alert alert-warning').removeClass('wpcf7-validation-errors wpcf7-response-output').css('font-size', '14px');
          }, false );
          document.addEventListener( 'wpcf7mailfailed', function( event ) {
              $('.wpcf7-response-output').addClass('alert alert-warning').removeClass('wpcf7-mail-sent-ng wpcf7-response-output').css('font-size', '14px');
          }, false );
          document.addEventListener( 'wpcf7mailsent', function( event ) {
              $('.wpcf7-response-output').addClass('alert alert-success').removeClass('wpcf7-validation-errors wpcf7-response-output').css('font-size', '14px');
          }, false );
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
